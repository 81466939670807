<template>
  <div class="">
    <b-button
      v-b-toggle.usersPrivilegeJobAssignmentOrders
      class="btn btn-lg btn-collapse"
    >
      {{ $t("jobAssignmentOrders.modelName") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="usersPrivilegeJobAssignmentOrders">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="jobAssignmentOrdersView"
              v-model="
                usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="jobAssignmentOrdersView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="jobAssignmentOrdersAdd"
              v-model="usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="jobAssignmentOrdersAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="jobAssignmentOrdersUpdate"
              v-model="
                usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="jobAssignmentOrdersUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="jobAssignmentOrdersArchive"
              v-model="
                usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="jobAssignmentOrdersArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="jobAssignmentOrdersDelete"
              v-model="
                usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="jobAssignmentOrdersDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="jobAssignmentOrdersRestore"
              v-model="
                usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="jobAssignmentOrdersRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegJobAssignmentOrders",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeJobAssignmentOrders"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
