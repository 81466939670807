<template>
  <div class="">
    <div class="row">
      <!-- <download-excel :data="json_data">
        Download Data
        <img src="require('@/assets/images/reports.svg')" />
      </download-excel> -->
      <!-- <download-excel
        class="btn btn-default btn-primary"
        :data="json_data"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="filename.xls"
      >
        Download Excel (you can customize this with html code!)
      </download-excel> -->
      <DownloadExcel
        className="btn btn-default btn-primary"
        :fields="json_fields"
        :data="json_data"
        name="zzzz"
        title="nazzl"
        worksheet="My Worksheet"
      />
    </div>
  </div>
</template>

<script>
// import CustomFileInput from "@/components/general/CustomFileInput.vue";
import DownloadExcel from "@/components/general/DownloadExcel.vue";
// import downloadExcel from "vue-json-excel";

export default {
  data() {
    return {
      mediaFile: "",
      // defaultImg: "https://api-admins-abl-v1.1.premcoprecast.com//_imagesEmployees/20211106143035PMr4396456.jpg",
      defaultImg: require("@/assets/images/reports.svg"),
      json_fields: {
        "Complete name": "name",
        City: "city",
        Telephone: "phone.mobile",
        "Telephone 2": {
          field: "phone.landline",
          callback: (value) => {
            return `Landline Phone - ${value}`;
          },
        },
      },
      json_data: [
        {
          name: "Tony Peña",
          city: "New York",
          country: "United States",
          birthdate: "1978-03-15",
          phone: {
            mobile: "1-541-754-3010",
            landline: "(541) 754-3010",
          },
        },
        {
          name: "Thessaloniki",
          city: "Athens",
          country: "Greece",
          birthdate: "1987-11-23",
          phone: {
            mobile: "+1 855 275 5071",
            landline: "(2741) 2621-244",
          },
        },
      ],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    // CustomFileInput,
    DownloadExcel,
    // downloadExcel,
  },
  methods: {},
  computed: {},
  created() {},
};
</script>
