var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeEmployeeWorkContracts",modifiers:{"usersPrivilegeEmployeeWorkContracts":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("employeeWorkContracts.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeEmployeeWorkContracts"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
            ),expression:"\n              usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeWorkContractsView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
            )?_vm._i(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeWorkContractsView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd
            ),expression:"\n              usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeWorkContractsAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeWorkContractsAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate
            ),expression:"\n              usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeWorkContractsUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate
            )?_vm._i(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeWorkContractsUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive
            ),expression:"\n              usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeWorkContractsArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive
            )?_vm._i(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeWorkContractsArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete
            ),expression:"\n              usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeWorkContractsDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeWorkContractsDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore
            ),expression:"\n              usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeWorkContractsRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore
            )?_vm._i(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeWorkContracts, "employeeWorkContractsRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeWorkContractsRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }