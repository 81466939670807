<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EmployeeAttendanceForm
      v-if="!isLoading"
      :employeeData="employeeData"
      :submitName="$t('add')"
      v-on:addOrUpdateEmployeeAttendance="addAttendanceManuale"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";
import EmployeeAttendanceForm from "@/components/employeesAttendance/EmployeeAttendanceForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeAttendanceAdd",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeAttendanceForm,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeData: {
        employeeToken: this.$route.params.employeeToken,
        attendedPlaceToken: "",
        attendedBranchToken: "",
        attendanceTypeToken: "",
        employeeSalaryEffectToken: "",
        calculationMethodTypeToken: "",
        employeeSalaryEffectFeeTypeToken: "",
        employeeSalaryEffectValue: "",
        employeeSalaryEffectFromSettingStatus: false,
        attendedDate: "",
        attendedTime: "",
        attendedNote: "",
        departureDate: "",
        departureTime: "",
        departureNote: "",
      },
    };
  },
  methods: {
    async addAttendanceManuale() {
      this.isLoading = true;
      const data = {
        language: this.language,
        userAuthorizeToken: this.userAuthorizeToken,
        employeeToken: this.employeeData.employeeToken,
        attendedPlaceToken: this.employeeData.attendedPlaceToken,
        attendedBranchToken: this.employeeData.attendedBranchToken,
        attendanceTypeToken: this.employeeData.attendanceTypeToken,
        employeeSalaryEffectToken: this.employeeData.employeeSalaryEffectToken,
        calculationMethodTypeToken:
          this.employeeData.calculationMethodTypeToken,
        employeeSalaryEffectFeeTypeToken:
          this.employeeData.employeeSalaryEffectFeeTypeToken,
        employeeSalaryEffectValue: this.employeeData.employeeSalaryEffectValue,
        employeeSalaryEffectFromSettingStatus:
          this.employeeData.employeeSalaryEffectFromSettingStatus,
        attendedPlaceName: this.$t("addedFromManagment"),
        departurePlaceName: this.$t("addedFromManagment"),
        attendedDate: this.employeeData.attendedDate,
        attendedTime: this.employeeData.attendedTime,
        attendedNote: this.employeeData.attendedNote,
        departureDate: this.employeeData.departureDate,
        departureTime: this.employeeData.departureTime,
        departureNote: this.employeeData.departureNote,
      };

      try {
        const response = await axios.post(
          `/EmployeesAttendance/AddAttendanceManuale`,
          data
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push(
            "/attendance-employee/" + this.employeeData.employeeToken
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        console.log(e);
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>

<style scoped lang="scss"></style>
