var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees.employeesView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
          .employeesAttendanceAttendanceList
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
          .employeesAttendanceDepatureList
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
          .employeesAttendanceViewAll
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theEmployees",modifiers:{"theEmployees":true}}],class:_vm.currentPage == 'theEmployees' ? 'active' : ''},[_vm._m(0),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theEmployees"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theEmployees"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees
                .employeesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employees' ? 'active' : '',attrs:{"to":"/employees"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theEmployees")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance
                .employeesAttendanceAttendanceList
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'add-attendance' ? 'active' : '',attrs:{"to":"/add-attendance"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/add-attendance.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("checkIn")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance
                .employeesAttendanceDepatureList
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'add-departure' ? 'active' : '',attrs:{"to":"/add-departure"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/add-departure.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("checkOut")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'attendance-history' ? 'active' : '',attrs:{"to":"/attendance-history"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/attendanceHistory.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("attendanceAndDepartureHistory")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeWorkSchedule
          .employeeWorkScheduleAdd
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData
          .usersPrivilegeEmployeeOfficialHolidaysPlans
          .employeeOfficialHolidaysPlansAdd
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesGrants
          .employeesGrantsView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeSalarySetting
          .employeeSalarySettingAdd
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.employeesSettings",modifiers:{"employeesSettings":true}}],class:_vm.currentPage == 'employeesSettings' ? 'active' : ''},[_vm._m(1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("employeesSettings"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"employeesSettings"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleAdd
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employeeWorkSchedule' ? 'active' : '',attrs:{"to":"/add-employee-work-schedule"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/work-schedule.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("EmployeeWorkScheduleAdd")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeeOfficialHolidaysPlans
                .employeeOfficialHolidaysPlansAdd
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employeeOfficialHolidaysPlan' ? 'active' : '',attrs:{"to":"/add-employee-official-holidays-plan"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/holiday.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("EmployeeOfficialHolidaysPlanAdd")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesGrants
                .employeesGrantsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employee-grants' ? 'active' : '',attrs:{"to":"/employee-grants"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/grants.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employeeGrants.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employeeSalarySetting' ? 'active' : '',attrs:{"to":"/add-employee-salary-setting"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employee-salary-setting.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("EmployeeSalarySettingAdd")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData
          .usersPrivilegeEmployeesInputSheetAttendance
          .employeesInputSheetAttendanceView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData
          .usersPrivilegeEmployeesInputSheetAttendanceMerge
          .EmployeesInputSheetAttendanceMergeView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.employeesSheetsSynchronization",modifiers:{"employeesSheetsSynchronization":true}}],class:_vm.currentPage == 'employeesSheetsSynchronization' ? 'active' : ''},[_vm._m(2),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("employeesSheetsSynchronization"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"employeesSheetsSynchronization"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesInputSheetAttendance
                .employeesInputSheetAttendanceView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employeesInputSheetsAttendance'
                ? 'active'
                : '',attrs:{"to":"/employees-input-sheets-attendance"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/sheets.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employeesInputSheetsAttendance")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesInputSheetAttendanceMerge
                .EmployeesInputSheetAttendanceMergeView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employeesInputSheetAttendanceMerges'
                ? 'active'
                : '',attrs:{"to":"/employees-input-sheet-attendance-merges"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/merge.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employeesInputSheetAttendanceMerges")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeBranches.branchesView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
          .employeeBranchesView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theBranches",modifiers:{"theBranches":true}}],class:_vm.currentPage == 'theBranches' ? 'active' : ''},[_vm._m(3),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theBranches"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theBranches"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeBranches
                .branchesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'branches' ? 'active' : '',attrs:{"to":"/branches"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branches.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theBranches")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
                .employeeBranchesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employee-branches' ? 'active' : '',attrs:{"to":"/employee-branches"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employee-branches.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employee-branches")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(_vm.usersBranchPrivilageView.treasuriesView) ||
      _vm.checkPrivilege(_vm.usersBranchPrivilageView.expensesView) ||
      _vm.checkPrivilege(_vm.usersBranchPrivilageView.revenuesView) ||
      _vm.checkPrivilege(_vm.usersBranchPrivilageView.treasuryTransactionsView) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeCustodies.custodiesView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesEnquirySalaries
          .employeesEnquirySalariesView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.finance",modifiers:{"finance":true}}],class:_vm.currentPage == 'finance' ? 'active' : ''},[_vm._m(4),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("finance"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"finance"}},[_c('ul',[(_vm.checkPrivilege(_vm.usersBranchPrivilageView.treasuriesView))?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'treasuries' ? 'active' : '',attrs:{"to":"/treasuries"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/treasuries.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theTreasuries")))])])],1):_vm._e(),(
            _vm.checkPrivilege(_vm.usersBranchPrivilageView.treasuryTransactionsView)
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'treasuries-transactions' ? 'active' : '',attrs:{"to":"/treasuries-transactions"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/treasuries-transactions.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("treasuriesTransactions")))])])],1):_vm._e(),(_vm.checkPrivilege(_vm.usersBranchPrivilageView.revenuesView))?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'revenues' ? 'active' : '',attrs:{"to":"/revenues"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/revenues.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("revenues")))])])],1):_vm._e(),(_vm.checkPrivilege(_vm.usersBranchPrivilageView.expensesView))?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'expenses' ? 'active' : '',attrs:{"to":"/expenses"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/expenses.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("expenses")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                .custodyViewAll
            ) ||
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                .custodyViewSelf
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'custodies' ? 'active' : '',attrs:{"to":{ name: 'Custodies' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/Custodies.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Custodies.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeCustodyExpenseOperations
                .custodyExpenseOperationsViewAll
            ) ||
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeCustodyExpenseOperations
                .custodyExpenseOperationsViewSelf
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'CustodyExpenseOperations' ? 'active' : '',attrs:{"to":{ name: 'CustodyExpenseOperations' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/CustodyExpenseOperations.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("CustodyExpenseOperations.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesEnquirySalaries
                .employeesEnquirySalariesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'SalaryInquiries' ? 'active' : '',attrs:{"to":{ name: 'SalaryInquiries' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/SalaryInquiries.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("SalaryInquiries.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEstablishmentsSettings
          .establishmentsSettingsView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEstablishmentYearSetting
          .establishmentYearSettingView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
          .additionalSettingsView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],class:_vm.currentPage == 'settings' ? 'active' : ''},[_vm._m(5),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("settings"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"settings"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEstablishmentsSettings
                .establishmentsSettingsView
            ) ||
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEstablishmentYearSetting
                .establishmentYearSettingView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-establishment' ? 'active' : '',attrs:{"to":"/settings-establishment"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-establishment.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-establishment")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                .additionalSettingsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-salary' ? 'active' : '',attrs:{"to":"/settings-salary"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-salary.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-salary")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                .additionalSettingsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-other' ? 'active' : '',attrs:{"to":"/settings-other"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-other.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-other")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers
          .usersEstablishmentPrivilegeView
      ) ||
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers
          .usersBranchPrivilegeView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.privileges",modifiers:{"privileges":true}}],class:_vm.currentPage == 'privileges' ? 'active' : ''},[_vm._m(6),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("privileges"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"privileges"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers
                .usersEstablishmentPrivilegeView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'establishment-privileges' ? 'active' : '',attrs:{"to":"/establishment-privileges"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/establishment-privileges.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("privilegesMsgs.establishment-privileges")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers
                .usersBranchPrivilegeView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'branch-privileges' ? 'active' : '',attrs:{"to":"/branch-privileges"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branch-privileges.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("privilegesMsgs.branch-privileges")))])])],1):_vm._e()])])],1):_vm._e(),_c('li',[_c('router-link',{class:_vm.currentPage == 'reports' ? 'active' : '',attrs:{"to":"/reports"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reports.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("reports")))])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees-settings.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/sheets.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branches.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/finance.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/privileges.svg")}})])}]

export { render, staticRenderFns }