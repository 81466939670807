<template>
  <div v-if="!disabledStatus" class="form-label-group" :class="className">
    <b-form-select
      @change="changeCC"
      v-model="value"
      :options="CCWithCCNameOptions"
      class="form-control"
    ></b-form-select>
    <img src="@/assets/images/earth.svg" />
  </div>
  <DataLabelGroup
    v-else
    :class="className"
    :value="value"
    :title="$t('countryCode')"
    :imgName="'earth.svg'"
  />
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  name: "TelPicker",
  data() {
    return {
      CCWithCCNameOptions: [
        { value: "", text: this.$t("selectCountryCode") },
        { value: "EG +20", text: this.$t("countryCodes.egypt") },
        { value: "GB +44", text: this.$t("countryCodes.uk") },
        { value: "US +1", text: this.$t("countryCodes.usa") },
        { value: "SA +966", text: this.$t("countryCodes.sa") },
      ],
    };
  },
  components: {
    DataLabelGroup,
  },
  methods: {
    async changeCC() {
      this.$emit("changeCC", this.value);
    },
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
