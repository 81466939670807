export default class UsersPrivilegeEmployeesGrants {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesGrantsView = false;
    this.employeesGrantsAdd = false;
    this.employeesGrantsUpdate = false;
    this.employeesGrantsCancel = false;
  }
  fillData(data) {
    if (data) {
      this.employeesGrantsView = data.employeesGrantsView;
      this.employeesGrantsAdd = data.employeesGrantsAdd;
      this.employeesGrantsUpdate = data.employeesGrantsUpdate;
      this.employeesGrantsCancel = data.employeesGrantsCancel;
    } else {
      this.setInitialValue();
    }
  }
}
