<template>
  <div class="">
    <b-button
      v-b-toggle.usersPrivilegeEmployeeWorkContracts
      class="btn btn-lg btn-collapse"
    >
      {{ $t("employeeWorkContracts.modelName") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="usersPrivilegeEmployeeWorkContracts">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkContractsView"
              v-model="
                usersPrivilegeEmployeeWorkContracts.employeeWorkContractsView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkContractsView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkContractsAdd"
              v-model="
                usersPrivilegeEmployeeWorkContracts.employeeWorkContractsAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkContractsAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkContractsUpdate"
              v-model="
                usersPrivilegeEmployeeWorkContracts.employeeWorkContractsUpdate
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkContractsUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkContractsArchive"
              v-model="
                usersPrivilegeEmployeeWorkContracts.employeeWorkContractsArchive
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkContractsArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkContractsDelete"
              v-model="
                usersPrivilegeEmployeeWorkContracts.employeeWorkContractsDelete
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkContractsDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkContractsRestore"
              v-model="
                usersPrivilegeEmployeeWorkContracts.employeeWorkContractsRestore
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkContractsRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegEmployeeWorkContracts",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeeWorkContracts"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
