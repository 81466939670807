<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="pageType == 'effect' ? 'col-md-6' : 'col-md-12'"
            :id="'attendedPlaceToken'"
            :value="employeeData.attendedPlaceToken"
            :options="branchTokenOptions"
            v-on:changeValue="employeeData.attendedPlaceToken = $event"
            :title="$t('selectBranch')"
            :imgName="'branches.svg'"
          />
          <template v-if="pageType == 'effect'">
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'attendanceTypeToken'"
              :value="employeeData.attendanceTypeToken"
              :options="attendanceTypeTokenOptions"
              v-on:changeValue="changeAttendanceType($event)"
              :title="$t('effectTypes.select')"
              :imgName="'type.svg'"
            />
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'employeeSalaryEffectToken'"
              :value="employeeData.employeeSalaryEffectToken"
              :options="employeeSalaryEffectTokenOptions"
              v-on:changeValue="changeSalaryEffect($event)"
              :title="$t('selectSalaryEffect')"
              :imgName="'type.svg'"
            />

            <CustomSelectBox
              :className="'col-md-6'"
              :id="'calculationMethodTypeToken'"
              :value="employeeData.calculationMethodTypeToken"
              :options="calculationMethodTypeTokenOptions"
              v-on:changeValue="
                employeeData.calculationMethodTypeToken = $event
              "
              :title="$t('selectCalculationMethodType')"
              :imgName="'type.svg'"
            />

            <CustomSelectBox
              :className="'col-md-6'"
              :id="'employeeSalaryEffectFeeTypeToken'"
              :value="employeeData.employeeSalaryEffectFeeTypeToken"
              :options="employeeSalaryEffectFeeTypeTokenOptions"
              v-on:changeValue="
                employeeData.employeeSalaryEffectFeeTypeToken = $event
              "
              :title="$t('selectSalaryEffectFeeType')"
              :imgName="'type.svg'"
            />
            <CustomInputInt
              :className="'col-md-6'"
              :id="'employeeSalaryEffectValue'"
              :value="employeeData.employeeSalaryEffectValue"
              :title="$t('defaultValue')"
              :imgName="'number.svg'"
              v-on:changeValue="employeeData.employeeSalaryEffectValue = $event"
            />
            <!-- <div class="form-label-group col-md-6">
              <input
                name="employeeSalaryEffectValue"
                id="employeeSalaryEffectValue"
                type="text"
                v-model="employeeData.employeeSalaryEffectValue"
                class="form-control"
                placeholder=" "
                maxlength="50"
              />
              <label for="textSearch">{{ $t("defaultValue") }}</label>
              <img src="@/assets/images/number.svg" />
            </div> -->
            <CustomCheckbox
              :className="'col-md-12'"
              :value="employeeData.employeeSalaryEffectFromSettingStatus"
              :title="$t('employeeSalaryEffectFromSettingStatus')"
              v-on:changeValue="
                employeeData.employeeSalaryEffectFromSettingStatus = $event
              "
            />
          </template>

          <template
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesAttendance
                  .employeesAttendanceAttendanceList
              )
            "
          >
            <div class="col-12 text-center">
              <label>{{ $t("dataOfStart") }}</label>
            </div>
            <DatePicker
              :className="'col-md-6'"
              :id="'attendedDate'"
              :value="employeeData.attendedDate"
              v-on:changeValue="employeeData.attendedDate = $event"
              :title="$t('date')"
              :language="language"
            />
            <TimePicker
              :className="'col-md-6'"
              :value="employeeData.attendedTime"
              v-on:changeValue="employeeData.attendedTime = $event"
              :title="$t('time')"
              :language="language"
            />
            <TextArea
              :className="'col-12'"
              :id="'attendedNote'"
              :value="employeeData.attendedNote"
              :title="$t('notes')"
              :imgName="'notes.svg'"
              v-on:changeValue="employeeData.attendedNote = $event"
            />
          </template>

          <template
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesAttendance
                  .employeesAttendanceDepatureList
              )
            "
          >
            <div class="col-12 text-center">
              <label>{{ $t("dataOfEnd") }}</label>
            </div>
            <DatePicker
              :className="'col-md-6'"
              :id="'departureDate'"
              :value="employeeData.departureDate"
              v-on:changeValue="employeeData.departureDate = $event"
              :title="$t('date')"
              :language="language"
            />
            <TimePicker
              :className="'col-md-6'"
              :value="employeeData.departureTime"
              v-on:changeValue="employeeData.departureTime = $event"
              :title="$t('time')"
              :language="language"
            />
            <TextArea
              :className="'col-12'"
              :id="'departureNote'"
              :value="employeeData.departureNote"
              :title="$t('notes')"
              :imgName="'notes.svg'"
              v-on:changeValue="employeeData.departureNote = $event"
            />
          </template>
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEmployeeAttendance"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="'/attendance-employee/' + employeeData.employeeToken"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
// import { ATTENDANCE_TYPE_TOKENS } from "@/utils/constantLists";
import axios from "axios";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import TextArea from "@/components/general/TextArea.vue";
import { setDataMultiLang } from "@/utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";
import EmployeeBranches from "@/models/employees/employeeBranches/EmployeeBranches";

export default {
  name: "EmployeeAttendanceForm",
  mixins: [privilegeMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInputInt,
    CustomCheckbox,
    CustomSelectBox,
    DatePicker,
    TimePicker,
    TextArea,
  },
  data() {
    return {
      pageType: "",
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      // attendanceTypeTokens: ATTENDANCE_TYPE_TOKENS,
      attendanceTypeTokenOptions: [],
      employeeBranches: new EmployeeBranches(),
      branchTokenOptions: [],
      employeeSalaryEffectTokenOptions: [],
      calculationMethodTypeTokenOptions: [],
      employeeSalaryEffectFeeTypeTokenOptions: [],
    };
  },
  props: ["employeeData", "submitName"],
  methods: {
    async addOrUpdateEmployeeAttendance() {
      // check page name
      if (this.pageType == "attendance") {
        // index 0 --> token EAT-7 of attendance type
        this.employeeData.attendanceTypeToken =
          this.attendanceTypeTokenOptions[0].value;
      }
      this.$emit("addOrUpdateEmployeeAttendance");
    },
    async changeAttendanceType(attendanceTypeToken) {
      this.employeeData.attendanceTypeToken = attendanceTypeToken;

      this.employeeData.employeeSalaryEffectToken = "";
      this.employeeData.calculationMethodTypeToken = "";
      this.employeeData.employeeSalaryEffectFeeTypeToken = "";
      this.employeeData.employeeSalaryEffectValue = "";
      this.getDialogOfEmployeeSalaryEffect(
        this.employeeData.attendanceTypeToken
      );
    },
    async changeSalaryEffect(data) {
      this.employeeData.employeeSalaryEffectToken = data;
      this.isLoading = true;

      try {
        let response = await axios.get(
          `/EmployeeSalaryEffect/GetEmployeeSalaryEffectDetails?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&employeeSalaryEffectToken=${this.employeeData.employeeSalaryEffectToken}`
        );
        let employeeSalaryEffectData = response.data.employeeSalaryEffectData;

        if (response.data.status == STATUS.SUCCESS) {
          this.employeeData.calculationMethodTypeToken =
            employeeSalaryEffectData.calculationMethodTypeToken;
          this.employeeData.employeeSalaryEffectFeeTypeToken =
            employeeSalaryEffectData.employeeSalaryEffectFeeTypeToken;
          this.employeeData.employeeSalaryEffectValue =
            employeeSalaryEffectData.defaultValue;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
    },
    async getDialogOfEmployeeAttendanceTypes() {
      this.isLoading = true;
      this.attendanceTypeTokenOptions = [];
      try {
        let items =
          this.$store.getters.userData.constantLists.listEmployeeAttendanceType;

        if (items.length) {
          for (let item in items) {
            this.attendanceTypeTokenOptions.push({
              value: items[item]["itemToken"],
              text: this.setDataMultiLang(
                this.language,
                items[item]["itemNameAr"],
                items[item]["itemNameEn"]
              ),
            });
          }
          if (this.pageType == "effect") {
            //remove index 0 --> token EAT-7 of attendance type
            this.attendanceTypeTokenOptions.shift();
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.attendanceTypeTokenOptions = [];
      }
      this.isLoading = false;
    },
    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      try {
        const response =
          await this.employeeBranches.employeeBranch.getAllEmployeeBranches(
            this.language,
            this.userAuthorizeToken,
            this.employeeBranches.pagination,
            this.employeeBranches.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.employeeBranches.fillData(response.data);
          const itemsData = this.employeeBranches.employeeBranchesData;
          if (itemsData.length) {
            for (let item in itemsData) {
              this.branchTokenOptions.push({
                value: itemsData[item]["branchToken"],
                text: itemsData[item]["branchNameCurrent"],
              });
            }
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.branchTokenOptions = [];
      }
      this.isLoading = false;
    },

    // async getDialogOfEmployeeBranchesAndStores() {
    //   this.isLoading = true;
    //   this.branchTokenOptions = [];
    //   this.branchTokenOptions.push({
    //     value: "",
    //     text: this.$t("selectBranch"),
    //   });
    //   try {
    //     const response = await axios.get(
    //       `/Users//GetDialogOfEmployeeBranchesAndStores?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&userSelfDataStatus=flase&employeeToken=${this.employeeData.employeeToken}`
    //     );
    //     const itemsData = response.data.itemsData;
    //     if (response.data.status == STATUS.SUCCESS) {
    //       for (let item in itemsData) {
    //         this.branchTokenOptions.push({
    //           value: itemsData[item]["itemToken"],
    //           text: itemsData[item]["itemName"],
    //         });
    //       }
    //     } else if (response.data.status == STATUS.INVALID_TOKEN) {
    //       this.$store.dispatch("logoutUser");
    //       this.$router.push("/").catch(() => {});
    //     }
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.showMsg(this.$t("errorCatch"));
    //     this.employeesData = null;
    //   }
    //   this.isLoading = false;
    // },
    async getDialogOfEmployeeSalaryEffect(employeeSalaryEffectTypeToken) {
      this.isLoading = true;
      employeeSalaryEffectTypeToken = employeeSalaryEffectTypeToken
        ? employeeSalaryEffectTypeToken
        : "";

      this.employeeSalaryEffectTokenOptions = [];

      try {
        const response = await axios.get(
          `/EmployeeSalaryEffect/GetDialogOfEmployeeSalaryEffect?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&employeeSalaryEffectTypeToken=${employeeSalaryEffectTypeToken}`
        );
        const itemsData = response.data.itemsData;
        if (itemsData.length > 0) {
          for (let item in itemsData) {
            this.employeeSalaryEffectTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async getDialogOfCalculationMethodType() {
      this.isLoading = true;
      this.calculationMethodTypeTokenOptions = [];

      let items =
        this.$store.getters.userData.constantLists.listCalculationMethodType;
      for (let item in items) {
        this.calculationMethodTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfFeesType() {
      this.isLoading = true;
      this.employeeSalaryEffectFeeTypeTokenOptions = [];

      let items = this.$store.getters.userData.constantLists.listFeesType;
      for (let item in items) {
        this.employeeSalaryEffectFeeTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    getPageInfo() {
      this.pageType = this.$route.meta.pageType || "";
    },
  },
  watch: {
    $route() {
      this.getPageInfo();
    },
  },
  async created() {
    this.getPageInfo();
    this.employeeBranches.filterData.employeeToken =
      this.employeeData.employeeToken;

    this.getDialogOfBranches();
    this.getDialogOfEmployeeAttendanceTypes();
    // this.getDialogOfEmployeeBranchesAndStores();
    this.getDialogOfEmployeeSalaryEffect(this.employeeData.attendanceTypeToken);
    this.getDialogOfCalculationMethodType();
    this.getDialogOfFeesType();
  },
};
</script>

<style scoped; lang="scss"></style>
