export default class UsersPrivilegeEmployeeWorkContracts {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeWorkContractsView = false;
    this.employeeWorkContractsAdd = false;
    this.employeeWorkContractsUpdate = false;
    this.employeeWorkContractsArchive = false;
    this.employeeWorkContractsRestore = false;
    this.employeeWorkContractsDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeeWorkContractsView = data.employeeWorkContractsView;
      this.employeeWorkContractsAdd = data.employeeWorkContractsAdd;
      this.employeeWorkContractsUpdate = data.employeeWorkContractsUpdate;
      this.employeeWorkContractsArchive = data.employeeWorkContractsArchive;
      this.employeeWorkContractsRestore = data.employeeWorkContractsRestore;
      this.employeeWorkContractsDelete = data.employeeWorkContractsDelete;
    } else {
      this.setInitialValue();
    }
  }
}
