export default class UsersPrivilegeEmployeeFolders {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeFoldersView = false;
    this.employeeFoldersAdd = false;
    this.employeeFoldersUpdate = false;
    this.employeeFoldersArchive = false;
    this.employeeFoldersRestore = false;
    this.employeeFoldersDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeeFoldersView = data.employeeFoldersView;
      this.employeeFoldersAdd = data.employeeFoldersAdd;
      this.employeeFoldersUpdate = data.employeeFoldersUpdate;
      this.employeeFoldersArchive = data.employeeFoldersArchive;
      this.employeeFoldersRestore = data.employeeFoldersRestore;
      this.employeeFoldersDelete = data.employeeFoldersDelete;
    } else {
      this.setInitialValue();
    }
  }
}
