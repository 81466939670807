export default class UsersPrivilegeCustodyExpenseOperations {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.custodyExpenseOperationsViewSelf = false;
    this.custodyExpenseOperationsViewAll = false;
    this.custodyExpenseOperationsAddSelf = false;
    this.custodyExpenseOperationsAddAll = false;
    this.custodyExpenseOperationsCancelSelf = false;
    this.custodyExpenseOperationsCancelAll = false;
  }
  fillData(data) {
    if (data) {
      this.custodyExpenseOperationsViewSelf =
        data.custodyExpenseOperationsViewSelf;
      this.custodyExpenseOperationsViewAll =
        data.custodyExpenseOperationsViewAll;
      this.custodyExpenseOperationsAddSelf =
        data.custodyExpenseOperationsAddSelf;
      this.custodyExpenseOperationsAddAll = data.custodyExpenseOperationsAddAll;
      this.custodyExpenseOperationsCancelSelf =
        data.custodyExpenseOperationsCancelSelf;
      this.custodyExpenseOperationsCancelAll =
        data.custodyExpenseOperationsCancelAll;
    } else {
      this.setInitialValue();
    }
  }
}
