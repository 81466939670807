var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.balancesPlansData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("balancesPlansCode")))]),_c('th',[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("establishmentYearSettings")))]),_c('th',[_vm._v(_vm._s(_vm.$t("actionDateTime")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),_c('th',[_vm._v(_vm._s(_vm.$t("info")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.balancesPlansData),function(balancesPlan,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(balancesPlan.balancesPlansCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(balancesPlan.balancesPlansNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(balancesPlan.establishmentYearSettingNameCurrnet))+" ")]),_c('td',[_vm._v(_vm._s(_vm.timeToLang(balancesPlan.dateTimeActionAdd)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(balancesPlan.balancesPlansNote)))]),_c('td',[_c('router-link',{attrs:{"to":("/balances-plan-details/" + (balancesPlan.balancesPlansToken)),"title":_vm.$t('info')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})])],1),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsUpdate
              )
            )?_c('td',[_c('router-link',{attrs:{"to":("/edit-balances-plan/" + (balancesPlan.balancesPlansToken)),"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])],1):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegAdditionalSettings.additionalSettingsArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.BalancesPlanDelete",modifiers:{"BalancesPlanDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setBalancesPlanData(balancesPlan)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }