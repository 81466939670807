<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateEmployeeBranch()"
    >
      <div :class="currentTab ? 'custom-tab-card' : 'my-card'">
        <div v-if="submitName != $t('add')" class="my-card">
          <div class="row">
            <DataLabelGroup
              :className="'col-md-6'"
              :value="employeeBranchData.employeeNameCurrent"
              :title="$t('employeeName')"
              :imgName="'employees.svg'"
            />
            <DataLabelGroup
              :className="'col-md-6'"
              :value="employeeBranchData.branchNameCurrent"
              :title="$t('branchName')"
              :imgName="'branches.svg'"
            />
          </div>
        </div>
        <div class="my-card">
          <div class="row">
            <CustomSelectBox
              v-if="submitName == $t('add')"
              :className="'col-md-6'"
              :id="'branchToken'"
              :value="employeeBranchData.branchToken"
              :options="branchTokenOptions"
              v-on:changeValue="employeeBranchChanged($event)"
              :title="$t('selectBranch')"
              :imgName="'branches.svg'"
            />
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'branchRoleToken'"
              :value="employeeBranchData.branchRoleToken"
              :options="branchRoleTokenOptions"
              v-on:changeValue="employeeBranchData.branchRoleToken = $event"
              :title="$t('setting.others.branchRolesMsgs.select')"
              :imgName="'branch-roles.svg'"
            />

            <CustomSelectBox
              :className="'col-md-6'"
              :id="'employeeWorkPlaceTypeToken'"
              :value="employeeBranchData.employeeWorkPlaceTypeToken"
              :options="employeeWorkPlaceTypeTokenOptions"
              v-on:changeValue="
                employeeBranchData.employeeWorkPlaceTypeToken = $event
              "
              :title="$t('selectEmployeeWorkPlaceType')"
              :imgName="'type.svg'"
            />
            <TextArea
              :className="submitName == $t('add') ? 'col-md-6' : 'col-md-12'"
              :id="'employeeBranchesNotes'"
              :value="employeeBranchData.employeeBranchesNotes"
              v-on:changeValue="
                employeeBranchData.employeeBranchesNotes = $event
              "
              :title="$t('notes')"
              :imgName="'notes.svg'"
            />

            <CustomSelectBoxMultiple
              :className="'col-12'"
              :id="'jobsTokenList'"
              :value="employeeBranchData.jobsTokenList"
              :options="jobTokenOptions"
              v-on:changeValue="jobsTokenListChanged($event)"
              :title="$t('selectJobs')"
              :imgName="'jobs.svg'"
              :returnArrayOfObjects="false"
            />
          </div>
        </div>

        <template v-if="submitName == $t('add')">
          <b-button v-b-toggle.employees class="btn btn-lg btn-collapse">
            {{ $t("employees") }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </b-button>
          <b-collapse id="employees">
            <div v-if="employeesArray" class="table-container">
              <table class="my-table">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        id="employeeSelectAll"
                        @click="employeeSelectAll"
                        v-model="employeeAllSelected"
                        class="checkbox"
                      />
                      <label class="mt-1 ml-2" for="employeeSelectAll">{{
                        $t("selectAll")
                      }}</label>
                    </th>
                    <th>#</th>
                    <th>{{ $t("employeeCode") }}</th>
                    <th>{{ $t("employeeName") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(employee, index) in employeesArray">
                    <tr :key="index">
                      <td rowspan="2">
                        <input
                          type="checkbox"
                          v-model="employeeBranchData.employeesList"
                          @click="employeeSelect"
                          :value="employee"
                          class="checkbox"
                        />
                      </td>
                      <td rowspan="2">{{ ++index }}</td>
                      <td>{{ isDataExist(employee.employeeCode) }}</td>
                      <td class="cell-with-image">
                        <img
                          class="item-img-table"
                          :src="employee.employeeImagePath"
                          :onerror="`this.src='${defaultImg}'`"
                        />
                        {{ isDataExist(employee.employeeNameCurrent) }}
                      </td>
                    </tr>
                    <tr :key="`${index}job`">
                      <td colspan="2">
                        <CustomSelectBoxMultiple
                          :className="'col-12'"
                          :id="'employeeJobs'"
                          :value="employee.employeeJobs"
                          :options="jobTokenOptions"
                          v-on:changeValue="employee.employeeJobs = $event"
                          :title="$t('selectJobs')"
                          :imgName="'jobs.svg'"
                          :returnArrayOfObjects="false"
                        />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <ExceptionWithImg v-else :msg="exceptionMsgEmployee" />
          </b-collapse>
        </template>
      </div>
      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <button @click.prevent="routeTo()" class="btn btn-cancel">
          {{ $t("cancel") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/user.jpg";
import { setDataMultiLang, isDataExist } from "@/utils/functions";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomSelectBoxMultiple from "@/components/general/CustomSelectBoxMultiple.vue";
import TextArea from "@/components/general/TextArea.vue";
import Branch from "@/models/branches/Branch";
import BranchRole from "@/models/settings/settingsOther/branchRoles/BranchRole";
import Jobs from "@/models/settings/settingsOther/jobs/Jobs";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";

export default {
  name: "EmployeeBranchForm",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DataLabelGroup,
    CustomSelectBox,
    CustomSelectBoxMultiple,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
      employeeWorkPlaceTypeTokenOptions: [],
      branch: new Branch(),
      branchTokenOptions: [],
      branchRoleData: new BranchRole(),
      branchRoleTokenOptions: [],
      jobs: new Jobs(),
      jobTokenOptions: [],
      employeeAllSelected: false,
    };
  },
  props: {
    employeeBranchData: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    employeesArray: {
      type: Array,
      default: () => [],
    },
    exceptionMsgEmployee: {
      type: String,
      default: "",
    },
  },
  methods: {
    routeTo() {
      if (this.currentTab) {
        this.$router
          .push({
            name: "EmployeeBranchesTab",
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "EmployeeBranches",
          })
          .catch(() => {});
      }
    },
    employeeSelectAll() {
      this.employeeAllSelected = !this.employeeAllSelected;
      this.employeeBranchData.employeesList = [];

      if (this.employeeAllSelected) {
        for (let employee in this.employeesArray) {
          this.employeeBranchData.employeesList.push(
            this.employeesArray[employee]
          );
        }
      }
    },
    employeeSelect() {
      setTimeout(() => {
        if (
          this.employeesArray.length ==
          this.employeeBranchData.employeesList.length
        )
          this.employeeAllSelected = true;
        else this.employeeAllSelected = false;
      }, 500);
    },

    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      try {
        let response = await this.branch.getDialogOfBranches(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    employeeBranchChanged(data) {
      this.employeeBranchData.branchToken = data;
      this.employeeBranchData.branchRoleToken = "";
      this.getDialogOfBranchRoles();
      this.employeeBranchData.jobsTokenList = [];
      this.jobs.filterData.branchToken = data;
      this.getDialogOfJobs();
    },
    async getDialogOfBranchRoles() {
      this.isLoading = true;
      this.branchRoleTokenOptions = [];
      try {
        let response = await this.branchRoleData.getDialogOfBranchRoles(
          this.language,
          this.userAuthorizeToken,
          this.employeeBranchData.branchToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeWorkPlaceTypes() {
      this.isLoading = true;
      this.employeeWorkPlaceTypeTokenOptions = [];
      let genders =
        this.$store.getters.userData.constantLists.listEmployeeWorkPlaceType;
      for (let item in genders) {
        this.employeeWorkPlaceTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfJobs() {
      this.isLoading = true;
      this.jobTokenOptions = [];
      try {
        let response = await this.jobs.job.getDialogOfJobs(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.jobTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async jobsTokenListChanged(v) {
      this.employeeBranchData.jobsTokenList = v;
      if (this.employeesArray) {
        for (let item in this.employeesArray) {
          this.employeesArray[item].employeeJobs = v;
        }
      }
    },
    async addOrUpdateEmployeeBranch() {
      this.$emit("addOrUpdateEmployeeBranch");
    },
    setDataMultiLang,
    isDataExist,
  },
  watch: {},
  async created() {
    if (this.currentTab) {
      this.employeeBranchData.employeesList = this.employeesArray;
      this.employeeAllSelected = true;
    }
    this.getDialogOfBranches();
    this.getDialogOfBranchRoles();
    this.getDialogOfEmployeeWorkPlaceTypes();
    this.getDialogOfJobs();
  },
};
</script>
