<template>
  <div class="">
    <b-button
      v-b-toggle.usersPrivilegeEmployeesEnquirySalariesPaid
      class="btn btn-lg btn-collapse"
    >
      {{ $t("EmployeesEnquirySalariesPaids.modelName") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="usersPrivilegeEmployeesEnquirySalariesPaid">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesEnquirySalariesPaidView"
              v-model="
                usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesEnquirySalariesPaidView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesEnquirySalariesPaidAdd"
              v-model="
                usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesEnquirySalariesPaidAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesEnquirySalariesPaidDelete"
              v-model="
                usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesEnquirySalariesPaidDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegEmployeesEnquirySalariesPaid",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeesEnquirySalariesPaid"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
