import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";

export default class BranchRole {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.branchRoleToken = "";
    this.branchRoleNameCurrent = "";
    this.branchRoleNameEn = "";
    this.branchRoleNameAr = "";
    this.branchRoleNameUnd = "";
    this.roleTypeToken = "RTT-1";
    this.roleTypeNameEn = "";
    this.roleTypeNameAr = "";
    this.roleTypeNameUnd = "";
    this.roleTypeNameCurrent = "";
    this.branchRoleDescriptionCurrent = "";
    this.branchRoleDescriptionEn = "";
    this.branchRoleDescriptionAr = "";
    this.branchRoleDescriptionUnd = "";
    this.branchRoleNotes = "";
    this.branchRoleArchiveStatus = "";
    this.paginationData = new PaginationData();
    this.textSearch = "";
  }
  fillData(data) {
    this.branchRoleToken = data.branchRoleToken;
    this.branchRoleNameCurrent = data.branchRoleNameCurrent;
    this.branchRoleNameEn = data.branchRoleNameEn;
    this.branchRoleNameAr = data.branchRoleNameAr;
    this.branchRoleNameUnd = data.branchRoleNameUnd;
    this.roleTypeToken = data.roleTypeToken;
    this.roleTypeNameEn = data.roleTypeNameEn;
    this.roleTypeNameAr = data.roleTypeNameAr;
    this.roleTypeNameUnd = data.roleTypeNameUnd;
    this.roleTypeNameCurrent = data.roleTypeNameCurrent;
    this.branchRoleDescriptionCurrent = data.branchRoleDescriptionCurrent;
    this.branchRoleDescriptionEn = data.branchRoleDescriptionEn;
    this.branchRoleDescriptionAr = data.branchRoleDescriptionAr;
    this.branchRoleDescriptionUnd = data.branchRoleDescriptionUnd;
    this.branchRoleNotes = data.branchRoleNotes;
    this.branchRoleArchiveStatus = data.branchRoleArchiveStatus;
  }

  async getAllBranchRoles(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/BranchRoles/GetAllBranchRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${this.paginationData.selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${this.textSearch}`
      );
      this.paginationData = {
        totalPages: response.data.branchRolesPagination.totalPages,
        totalItems: response.data.branchRolesPagination.totalItems,
        countItemsInPage: response.data.branchRolesPagination.countItemsInPage,
        selfPage: response.data.branchRolesPagination.selfPage,
      };
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getBranchRoleDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/BranchRoles/GetBranchRoleDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&branchRoleToken=${this.branchRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfBranchRoles(language, userAuthorizeToken, branchToken) {
    try {
      let response = await axios.get(
        `/BranchRoles/GetDialogOfBranchRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&branchToken=${branchToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateBranchRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      branchRoleToken: this.branchRoleToken,
      branchRoleNameAr: this.branchRoleNameAr,
      branchRoleNameUnd: this.branchRoleNameUnd,
      roleNameUnd: this.roleNameUnd,
      roleTypeToken: this.roleTypeToken,
      branchRoleDescriptionEn: this.branchRoleDescriptionEn,
      branchRoleDescriptionAr: this.branchRoleDescriptionAr,
      branchRoleDescriptionUnd: this.branchRoleDescriptionUnd,
      branchRoleNotes: this.branchRoleNotes,
    };

    try {
      let response = "";
      if (this.branchRoleToken == "" || this.branchRoleToken == undefined) {
        response = await axios.post(`/BranchRoles/AddBranchRole`, data);
      } else {
        response = await axios.post(`/BranchRoles/UpdateBranchRole`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveBranchRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      branchRoleToken: this.branchRoleToken,
    };

    try {
      let response = await axios.post(`/BranchRoles/ArchiveBranchRole`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
