<template>
  <div class="">
    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12 col-lg-6'"
            :id="`cumulativeCalculationTypeToken`"
            :value="deductionsPlanData.cumulativeCalculationTypeToken"
            :options="cumulativeCalculationTypeTokenOptions"
            v-on:changeValue="cumulativeCalculationTypeChanged($event)"
            :title="$t('deductions.selectCumulativeCalculation')"
            :imgName="'type.svg'"
          />
          <CustomCheckbox
            :isDisabled="isDisabledCheckBox"
            :className="'col-md-6 col-lg-3'"
            :value="deductionsPlanData.cumulativeCalculationLateAttendaceStatus"
            :title="$t('deductions.cumulativeCalculationLateAttendaceStatus')"
            v-on:changeValue="
              deductionsPlanData.cumulativeCalculationLateAttendaceStatus =
                $event
            "
          />
          <CustomCheckbox
            :isDisabled="isDisabledCheckBox"
            :className="'col-md-6 col-lg-3'"
            :value="
              deductionsPlanData.cumulativeCalculationEarlyDepartureStatus
            "
            :title="$t('deductions.cumulativeCalculationEarlyDepartureStatus')"
            v-on:changeValue="
              deductionsPlanData.cumulativeCalculationEarlyDepartureStatus =
                $event
            "
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'deductionsPlansNameAr'"
            :value="deductionsPlanData.deductionsPlansNameAr"
            v-on:changeValue="deductionsPlanData.deductionsPlansNameAr = $event"
            :title="$t('nameAr')"
            :imgName="'deductions.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'deductionsPlansNameEn'"
            :value="deductionsPlanData.deductionsPlansNameEn"
            v-on:changeValue="deductionsPlanData.deductionsPlansNameEn = $event"
            :title="$t('nameEn')"
            :imgName="'deductions.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'deductionsPlansNameUnd'"
            :value="deductionsPlanData.deductionsPlansNameUnd"
            v-on:changeValue="
              deductionsPlanData.deductionsPlansNameUnd = $event
            "
            :title="$t('nameUnd')"
            :imgName="'deductions.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'deductionsPlansCode'"
            :value="deductionsPlanData.deductionsPlansCode"
            v-on:changeValue="deductionsPlanData.deductionsPlansCode = $event"
            :title="$t('deductions.code')"
            :imgName="'number.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'deductionsPlansNote'"
            :value="deductionsPlanData.deductionsPlansNote"
            v-on:changeValue="deductionsPlanData.deductionsPlansNote = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <DeductionsPlanFormSlice
        :deductionsPlansDetailsSlideData="
          deductionsPlanData.deductionsPlansDetailsSlideDataLatelyAttendance
        "
        :deductionsPlansDetailsSlideMsg="$t('deductions.typeLatelyAttendance')"
        :id="'LatelyAttendance'"
      />
      <DeductionsPlanFormSlice
        :deductionsPlansDetailsSlideData="
          deductionsPlanData.deductionsPlansDetailsSlideDataEarlyDeparture
        "
        :deductionsPlansDetailsSlideMsg="$t('deductions.typeEarlyDeparture')"
        :id="'EarlyDeparture'"
      />
      <DeductionsPlanFormSlice
        :deductionsPlansDetailsSlideData="
          deductionsPlanData.deductionsPlansDetailsSlideDataLostTime
        "
        :deductionsPlansDetailsSlideMsg="$t('deductions.typeLostTime')"
        :id="'LostTime'"
      />
      <!-- <div>
        <div
          class="my-card"
          v-for="(
            deductionsPlan, index
          ) in deductionsPlanData.deductionsPlansDetailsSlideData"
          :key="index"
        >
          <span class="my-card-title">{{ index + 1 }}</span>

          <span class="remove-slice-container" v-if="index != 0">
            <button
              @click="removeSlice(index)"
              type="button"
              class="btn btn-remove-slice"
            >
              ×
            </button>
          </span>
          <div class="row">
            <CustomSelectBox
              :className="'col-md-12'"
              :id="`deductionsPlansDetailsSlideData[${index}][slideTypeToken]`"
              :value="deductionsPlan.slideTypeToken"
              :options="slideTypeTokenOptions"
              v-on:changeValue="deductionsSlideTypeChanged($event, index)"
              :title="$t('deductions.selectSlideType')"
              :imgName="'type.svg'"
            />

            <CustomSelectBox
              :className="'col-md-12 col-lg-6'"
              :id="`deductionsPlansDetailsSlideData[${index}][employeeSalaryEffectToken]`"
              :value="deductionsPlan.employeeSalaryEffectToken"
              :options="employeeSalaryEffectTokenOptions"
              v-on:changeValue="
                deductionsPlan.employeeSalaryEffectToken = $event
              "
              :title="$t('selectSalaryEffect')"
              :imgName="'money.svg'"
            />
            <CustomCheckbox
              :className="'col-md-6 col-lg-2'"
              :id="`deductionsPlansDetailsSlideData[${index}][effectOnEmployeeSalaryStatus]`"
              :value="deductionsPlan.effectOnEmployeeSalaryStatus"
              :title="$t('deductions.effectOnEmployeeSalaryStatus')"
              v-on:changeValue="
                deductionsPlan.effectOnEmployeeSalaryStatus = $event
              "
            />
            <CustomInputFloat
              :className="'col-md-6 col-lg-4'"
              :id="`deductionsPlansDetailsSlideData[${index}][deductionNumberInSeconds]`"
              :value="deductionsPlan.hourfactor"
              v-on:changeValue="deductionsPlan.hourfactor = $event"
              :title="$t('deductionsPlanDetails.hourfactor')"
              :imgName="'hours.svg'"
            />

            <DataLabelGroup
              :className="'col-md-6 col-lg-6'"
              :value="
                index == 0
                  ? convertTimeAndDaysToString(
                      setTimeFromIncremented(0, 0, 0, 0, index)
                    )
                  : convertTimeAndDaysToString(
                      setTimeFromIncremented(
                        deductionsPlanData.deductionsPlansDetailsSlideData[
                          index - 1
                        ].toNumberInSeconds,
                        deductionsPlanData.deductionsPlansDetailsSlideData[
                          index - 1
                        ].toNumberInMinutes,
                        deductionsPlanData.deductionsPlansDetailsSlideData[
                          index - 1
                        ].toNumberInHours,
                        deductionsPlanData.deductionsPlansDetailsSlideData[
                          index - 1
                        ].toNumberInDays,
                        index
                      )
                    )
              "
              :title="$t('deductionsPlanDetails.maxFrom')"
              :imgName="'dateAndTime.svg'"
            />
            <TimeCounter
              :dayValue="deductionsPlan.toNumberInDays"
              :hourValue="deductionsPlan.toNumberInHours"
              :minuteValue="deductionsPlan.toNumberInMinutes"
              :secondValue="deductionsPlan.toNumberInSeconds"
              @input="
                deductionsPlan.toNumberInDays = $event.days;
                deductionsPlan.toNumberInHours = $event.hours;
                deductionsPlan.toNumberInMinutes = $event.minutes;
                deductionsPlan.toNumberInSeconds = $event.seconds;
              "
              :className="'col-md-6'"
              :title="$t('deductionsPlanDetails.maxTo')"
              :imgName="'dateAndTime.svg'"
              :dayStatus="true"
            />

            <CustomCheckbox
              :className="'col-md-12'"
              :id="`deductionsPlansDetailsSlideData[${index}][fullShiftDeductionStatus]`"
              :value="deductionsPlan.fullShiftDeductionStatus"
              :title="$t('deductions.fullShiftDeductionStatus')"
              v-on:changeValue="
                deductionsPlan.fullShiftDeductionStatus = $event
              "
            />
          </div>
        </div>

        <div class="add-slice-container">
          <button
            @click="addSlice"
            type="button"
            class="btn btn-add-slice"
            :title="$t('addSlice')"
          >
            +
          </button>
        </div>
      </div> -->

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateDeductionsPlan"
        >
          {{ submitName }}
        </button>
        <router-link :to="'/deductions-plans/'" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import {
  convertTimeAndDaysToString,
  setDataMultiLang,
  getTimeIncremented,
} from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
// import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
// import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
// import TimeCounter from "@/components/general/TimeCounter.vue";
import TextArea from "@/components/general/TextArea.vue";
import SalaryEffect from "@/models/settings/settingsSalary/salaryEffects/SalaryEffect";
import DeductionsPlansSlideDetails from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlansSlideDetails";
import DeductionsPlanFormSlice from "./DeductionsPlanFormSlice.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "DeductionsPlanForm",
  mixins: [createToastMixin],
  components: {
    CustomInput,
    CustomSelectBox,
    CustomCheckbox,
    // CustomInputFloat,
    // DataLabelGroup,
    // TimeCounter,
    TextArea,
    DeductionsPlanFormSlice,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      timeFromIncremented: {},
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeSalaryEffectData: new SalaryEffect(),
      employeeSalaryEffectTokenOptions: [],
      slideTypeTokenOptions: [],
      cumulativeCalculationTypeTokenOptions: [],
    };
  },
  props: ["deductionsPlanData", "submitName"],
  methods: {
    setDataMultiLang,
    convertTimeAndDaysToString(timeObject) {
      return convertTimeAndDaysToString(
        timeObject.seconds,
        timeObject.minutes,
        timeObject.hours,
        timeObject.days
      ).data.data.string;
    },
    getTimeIncremented,
    addSlice() {
      this.deductionsPlanData.deductionsPlansDetailsSlideData.push(
        new DeductionsPlansSlideDetails()
      );
    },
    removeSlice(index) {
      this.deductionsPlanData.deductionsPlansDetailsSlideData.splice(index, 1);
    },
    setTimeFromIncremented(seconds, minutes, hours, days, index) {
      let timeToPreviouse = { seconds, minutes, hours, days };
      // console.log(index);
      // console.log(timeToPreviouse);

      let timeToPreviouseIncremented = this.getTimeIncremented(timeToPreviouse);
      // save in model
      this.deductionsPlanData.deductionsPlansDetailsSlideData[
        index
      ].fromNumberInSeconds = timeToPreviouseIncremented.seconds;
      this.deductionsPlanData.deductionsPlansDetailsSlideData[
        index
      ].fromNumberInMinutes = timeToPreviouseIncremented.minutes;
      this.deductionsPlanData.deductionsPlansDetailsSlideData[
        index
      ].fromNumberInHours = timeToPreviouseIncremented.hours;
      this.deductionsPlanData.deductionsPlansDetailsSlideData[
        index
      ].fromNumberInDays = timeToPreviouseIncremented.days;

      return this.getTimeIncremented(timeToPreviouse);
    },
    deductionsPlanTypeChanged(data, index) {
      this.deductionsPlanData.deductionsPlansDetailsSlideData[
        index
      ].deductionTypeToken = data;
      if (
        this.deductionsPlanData.deductionsPlansDetailsSlideData[index]
          .deductionTypeToken == this.deductionTypeTokens.Sallary
      ) {
        this.deductionsPlanData.deductionsPlansDetailsSlideData[
          index
        ].employeeSalaryEffectToken = "";
      }
    },
    deductionsSlideTypeChanged(data, index) {
      this.deductionsPlanData.deductionsPlansDetailsSlideData[
        index
      ].slideTypeToken = data;
    },
    cumulativeCalculationTypeChanged(data) {
      this.deductionsPlanData.cumulativeCalculationTypeToken = data;
      if (!this.deductionsPlanData.cumulativeCalculationTypeToken) {
        this.deductionsPlanData.cumulativeCalculationLateAttendaceStatus = false;
        this.deductionsPlanData.cumulativeCalculationEarlyDepartureStatus = false;
      }
    },
    async addOrUpdateDeductionsPlan() {
      this.$emit("addOrUpdateDeductionsPlan");
    },
    async getDialogOfSlideType() {
      this.isLoading = true;
      this.slideTypeTokenOptions = [];
      this.slideTypeTokenOptions.push({
        value: "",
        text: this.$t("deductions.selectType"),
      });
      let deductions =
        this.$store.getters.userData.constantLists.listDeductionSlideType;
      for (let item in deductions) {
        this.slideTypeTokenOptions.push({
          value: deductions[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            deductions[item]["itemNameAr"],
            deductions[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfCumulativeCalculationType() {
      this.isLoading = true;
      this.cumulativeCalculationTypeTokenOptions = [];
      this.cumulativeCalculationTypeTokenOptions.push({
        value: "",
        text: this.$t("deductions.selectCumulativeCalculation"),
      });
      let deductions =
        this.$store.getters.userData.constantLists
          .listCumulativeCalculationType;
      for (let item in deductions) {
        this.cumulativeCalculationTypeTokenOptions.push({
          value: deductions[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            deductions[item]["itemNameAr"],
            deductions[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfSalaryEffects() {
      this.isLoading = true;
      this.employeeSalaryEffectTokenOptions = [];
      this.employeeSalaryEffectTokenOptions.push({
        value: "",
        text: this.$t("selectSalaryEffect"),
      });
      try {
        let response =
          await this.employeeSalaryEffectData.getDialogOfEmployeeSalaryEffect(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeSalaryEffectTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  computed: {
    isDisabledCheckBox() {
      return this.deductionsPlanData.cumulativeCalculationTypeToken
        ? false
        : true;
    },
  },
  async created() {
    this.getDialogOfSalaryEffects();
    this.getDialogOfSlideType();
    this.getDialogOfCumulativeCalculationType();
  },
};
</script>
