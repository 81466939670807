export default class UsersPrivilegeEmployeesEnquirySalaries {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesEnquirySalariesView = false;
    this.employeesEnquirySalariesAdd = false;
    this.employeesEnquirySalariesDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeesEnquirySalariesView = data.employeesEnquirySalariesView;
      this.employeesEnquirySalariesAdd = data.employeesEnquirySalariesAdd;
      this.employeesEnquirySalariesDelete = data.employeesEnquirySalariesDelete;
    } else {
      this.setInitialValue();
    }
  }
}
