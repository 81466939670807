export default class UsersPrivilegeEmployeesEnquirySalariesPaid {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesEnquirySalariesPaidView = false;
    this.employeesEnquirySalariesPaidAdd = false;
    this.employeesEnquirySalariesPaidDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeesEnquirySalariesPaidView =
        data.employeesEnquirySalariesPaidView;
      this.employeesEnquirySalariesPaidAdd =
        data.employeesEnquirySalariesPaidAdd;
      this.employeesEnquirySalariesPaidDelete =
        data.employeesEnquirySalariesPaidDelete;
    } else {
      this.setInitialValue();
    }
  }
}
