<template>
  <download-excel
    class="btn"
    :class="className"
    :fields="fields"
    :data="data"
    :name="`${name ? name : 'file'}.xls`"
    :worksheet="worksheet"
  >
    {{ title ? title : $t("downloadExcel") }}
  </download-excel>
</template>

<script>
import downloadExcel from "vue-json-excel";

export default {
  name: "DownloadExcel",
  data() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    return {
      fullDateTime: `${year}${month}${day}`,
    };
  },
  components: {
    downloadExcel,
  },
  methods: {},
  props: {
    fields: {
      type: Object,
    },
    data: {
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "excel-file",
    },
    title: {
      type: String,
      default: "",
    },
    worksheet: {
      type: String,
      default: "My Worksheet",
    },
  },
};
</script>
