export default class UsersPrivilegeJobAssignmentOrders {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.jobAssignmentOrdersView = false;
    this.jobAssignmentOrdersAdd = false;
    this.jobAssignmentOrdersUpdate = false;
    this.jobAssignmentOrdersArchive = false;
    this.jobAssignmentOrdersRestore = false;
    this.jobAssignmentOrdersDelete = false;
  }
  fillData(data) {
    if (data) {
      this.jobAssignmentOrdersView = data.jobAssignmentOrdersView;
      this.jobAssignmentOrdersAdd = data.jobAssignmentOrdersAdd;
      this.jobAssignmentOrdersUpdate = data.jobAssignmentOrdersUpdate;
      this.jobAssignmentOrdersArchive = data.jobAssignmentOrdersArchive;
      this.jobAssignmentOrdersRestore = data.jobAssignmentOrdersRestore;
      this.jobAssignmentOrdersDelete = data.jobAssignmentOrdersDelete;
    } else {
      this.setInitialValue();
    }
  }
}
