<template>
  <div class="">
    <b-button
      v-b-toggle.usersPrivilegeEmployeesGrants
      class="btn btn-lg btn-collapse"
    >
      {{ $t("Grants.modelName") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="usersPrivilegeEmployeesGrants">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesGrantsView"
              v-model="usersPrivilegeEmployeesGrants.employeesGrantsView"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesGrantsView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesGrantsAdd"
              v-model="usersPrivilegeEmployeesGrants.employeesGrantsAdd"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesGrantsAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesGrantsUpdate"
              v-model="usersPrivilegeEmployeesGrants.employeesGrantsUpdate"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesGrantsUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeesGrantsCancel"
              v-model="usersPrivilegeEmployeesGrants.employeesGrantsCancel"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeesGrantsCancel">{{
              $t("cancel")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegEmployeesGrants",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeesGrants"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
