var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.deductionsPlansDetailsSlideMsg))]),(_vm.deductionsPlansDetailsSlideData.length == 0)?_c('div',{staticClass:"my-card-no-content"},[_vm._v(" "+_vm._s(_vm.$t("deductions.thereAreNoSlides"))+" ")]):_vm._l((_vm.deductionsPlansDetailsSlideData),function(deductionsPlan,index){return _c('div',{key:index,staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(index + 1))]),(index != 0)?_c('span',{staticClass:"remove-slice-container"},[_c('button',{staticClass:"btn btn-remove-slice",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeSlice(index)}}},[_vm._v(" × ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12 col-lg-6',"id":(_vm.id + "[" + index + "][employeeSalaryEffectToken]"),"value":deductionsPlan.employeeSalaryEffectToken,"options":_vm.employeeSalaryEffectTokenOptions,"title":_vm.$t('selectSalaryEffect'),"imgName":'money.svg'},on:{"changeValue":function($event){deductionsPlan.employeeSalaryEffectToken = $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6 col-lg-2',"id":(_vm.id + "[" + index + "][effectOnEmployeeSalaryStatus]"),"value":deductionsPlan.effectOnEmployeeSalaryStatus,"title":_vm.$t('deductions.effectOnEmployeeSalaryStatus')},on:{"changeValue":function($event){deductionsPlan.effectOnEmployeeSalaryStatus = $event}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-6 col-lg-4',"id":(_vm.id + "[" + index + "][deductionNumberInSeconds]"),"value":deductionsPlan.hourfactor,"title":_vm.$t('deductionsPlanDetails.hourfactor'),"imgName":'hours.svg'},on:{"changeValue":function($event){deductionsPlan.hourfactor = $event}}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6 col-lg-6',"value":index == 0
              ? _vm.convertTimeAndDaysToString({
                  days: 0,
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                })
              : _vm.convertTimeAndDaysToString(
                  _vm.setTimeFromIncremented(
                    _vm.deductionsPlansDetailsSlideData[index - 1]
                      .toNumberInSeconds,
                    _vm.deductionsPlansDetailsSlideData[index - 1]
                      .toNumberInMinutes,
                    _vm.deductionsPlansDetailsSlideData[index - 1]
                      .toNumberInHours,
                    _vm.deductionsPlansDetailsSlideData[index - 1].toNumberInDays,
                    index
                  )
                ),"title":_vm.$t('deductionsPlanDetails.maxFrom'),"imgName":'dateAndTime.svg'}}),_c('TimeCounter',{attrs:{"dayValue":deductionsPlan.toNumberInDays,"hourValue":deductionsPlan.toNumberInHours,"minuteValue":deductionsPlan.toNumberInMinutes,"secondValue":deductionsPlan.toNumberInSeconds,"className":'col-md-6',"title":_vm.$t('deductionsPlanDetails.maxTo'),"imgName":'dateAndTime.svg',"dayStatus":true},on:{"input":function($event){deductionsPlan.toNumberInDays = $event.days;
            deductionsPlan.toNumberInHours = $event.hours;
            deductionsPlan.toNumberInMinutes = $event.minutes;
            deductionsPlan.toNumberInSeconds = $event.seconds;}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-12',"id":(_vm.id + "[" + index + "][fullShiftDeductionStatus]"),"value":deductionsPlan.fullShiftDeductionStatus,"title":_vm.$t('deductions.fullShiftDeductionStatus')},on:{"changeValue":function($event){deductionsPlan.fullShiftDeductionStatus = $event}}})],1)])}),_c('div',{staticClass:"add-slice-container"},[_c('button',{staticClass:"btn btn-add-slice",attrs:{"type":"button","title":_vm.$t('addSlice')},on:{"click":_vm.addSlice}},[_vm._v(" + ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }