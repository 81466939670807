<template>
  <div class="my-card">
    <span class="my-card-title">{{ deductionsPlansDetailsSlideMsg }}</span>

    <div
      v-if="deductionsPlansDetailsSlideData.length == 0"
      class="my-card-no-content"
    >
      {{ $t("deductions.thereAreNoSlides") }}
    </div>
    <template v-else>
      <div
        class="my-card"
        v-for="(deductionsPlan, index) in deductionsPlansDetailsSlideData"
        :key="index"
      >
        <span class="my-card-title">{{ index + 1 }}</span>

        <span class="remove-slice-container" v-if="index != 0">
          <button
            @click="removeSlice(index)"
            type="button"
            class="btn btn-remove-slice"
          >
            ×
          </button>
        </span>
        <div class="row">
          <!-- <CustomSelectBox
            :className="'col-md-12'"
            :id="`${id}[${index}][slideTypeToken]`"
            :value="deductionsPlan.slideTypeToken"
            :options="slideTypeTokenOptions"
            v-on:changeValue="deductionsSlideTypeChanged($event, index)"
            :title="$t('deductions.selectType')"
            :imgName="'type.svg'"
          /> -->

          <CustomSelectBox
            :className="'col-md-12 col-lg-6'"
            :id="`${id}[${index}][employeeSalaryEffectToken]`"
            :value="deductionsPlan.employeeSalaryEffectToken"
            :options="employeeSalaryEffectTokenOptions"
            v-on:changeValue="deductionsPlan.employeeSalaryEffectToken = $event"
            :title="$t('selectSalaryEffect')"
            :imgName="'money.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-2'"
            :id="`${id}[${index}][effectOnEmployeeSalaryStatus]`"
            :value="deductionsPlan.effectOnEmployeeSalaryStatus"
            :title="$t('deductions.effectOnEmployeeSalaryStatus')"
            v-on:changeValue="
              deductionsPlan.effectOnEmployeeSalaryStatus = $event
            "
          />
          <CustomInputFloat
            :className="'col-md-6 col-lg-4'"
            :id="`${id}[${index}][deductionNumberInSeconds]`"
            :value="deductionsPlan.hourfactor"
            v-on:changeValue="deductionsPlan.hourfactor = $event"
            :title="$t('deductionsPlanDetails.hourfactor')"
            :imgName="'hours.svg'"
          />

          <DataLabelGroup
            :className="'col-md-6 col-lg-6'"
            :value="
              index == 0
                ? convertTimeAndDaysToString({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                : convertTimeAndDaysToString(
                    setTimeFromIncremented(
                      deductionsPlansDetailsSlideData[index - 1]
                        .toNumberInSeconds,
                      deductionsPlansDetailsSlideData[index - 1]
                        .toNumberInMinutes,
                      deductionsPlansDetailsSlideData[index - 1]
                        .toNumberInHours,
                      deductionsPlansDetailsSlideData[index - 1].toNumberInDays,
                      index
                    )
                  )
            "
            :title="$t('deductionsPlanDetails.maxFrom')"
            :imgName="'dateAndTime.svg'"
          />
          <TimeCounter
            :dayValue="deductionsPlan.toNumberInDays"
            :hourValue="deductionsPlan.toNumberInHours"
            :minuteValue="deductionsPlan.toNumberInMinutes"
            :secondValue="deductionsPlan.toNumberInSeconds"
            @input="
              deductionsPlan.toNumberInDays = $event.days;
              deductionsPlan.toNumberInHours = $event.hours;
              deductionsPlan.toNumberInMinutes = $event.minutes;
              deductionsPlan.toNumberInSeconds = $event.seconds;
            "
            :className="'col-md-6'"
            :title="$t('deductionsPlanDetails.maxTo')"
            :imgName="'dateAndTime.svg'"
            :dayStatus="true"
          />

          <CustomCheckbox
            :className="'col-md-12'"
            :id="`${id}[${index}][fullShiftDeductionStatus]`"
            :value="deductionsPlan.fullShiftDeductionStatus"
            :title="$t('deductions.fullShiftDeductionStatus')"
            v-on:changeValue="deductionsPlan.fullShiftDeductionStatus = $event"
          />
        </div>
      </div>
    </template>

    <div class="add-slice-container">
      <button
        @click="addSlice"
        type="button"
        class="btn btn-add-slice"
        :title="$t('addSlice')"
      >
        +
      </button>
    </div>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import {
  convertTimeAndDaysToString,
  setDataMultiLang,
  getTimeIncremented,
} from "@/utils/functions";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import TimeCounter from "@/components/general/TimeCounter.vue";
import SalaryEffect from "@/models/settings/settingsSalary/salaryEffects/SalaryEffect";
import DeductionsPlansSlideDetails from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlansSlideDetails";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "DeductionsPlanForm",
  mixins: [createToastMixin],
  components: {
    CustomSelectBox,
    CustomCheckbox,
    CustomInputFloat,
    DataLabelGroup,
    TimeCounter,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeSalaryEffectData: new SalaryEffect(),
      employeeSalaryEffectTokenOptions: [],
      // slideTypeTokenOptions: [],
    };
  },
  props: [
    "deductionsPlansDetailsSlideData",
    "deductionsPlansDetailsSlideMsg",
    "id",
  ],
  methods: {
    setDataMultiLang,
    getTimeIncremented,
    convertTimeAndDaysToString(timeObject) {
      return convertTimeAndDaysToString(
        timeObject.seconds,
        timeObject.minutes,
        timeObject.hours,
        timeObject.days
      ).data.data.string;
    },
    addSlice() {
      this.deductionsPlansDetailsSlideData.push(
        new DeductionsPlansSlideDetails()
      );
    },
    removeSlice(index) {
      this.deductionsPlansDetailsSlideData.splice(index, 1);
    },
    setTimeFromIncremented(seconds, minutes, hours, days, index) {
      let timeToPreviouse = { seconds, minutes, hours, days };
      // console.log(index);
      // console.log(timeToPreviouse);

      let timeToPreviouseIncremented = this.getTimeIncremented(timeToPreviouse);
      // save in model
      this.deductionsPlansDetailsSlideData[index].fromNumberInSeconds =
        timeToPreviouseIncremented.seconds;
      this.deductionsPlansDetailsSlideData[index].fromNumberInMinutes =
        timeToPreviouseIncremented.minutes;
      this.deductionsPlansDetailsSlideData[index].fromNumberInHours =
        timeToPreviouseIncremented.hours;
      this.deductionsPlansDetailsSlideData[index].fromNumberInDays =
        timeToPreviouseIncremented.days;

      return this.getTimeIncremented(timeToPreviouse);
    },
    // deductionsSlideTypeChanged(data, index) {
    //   this.deductionsPlansDetailsSlideData[index].slideTypeToken = data;
    // },
    // async getDialogOfSlideType() {
    //   this.isLoading = true;
    //   this.slideTypeTokenOptions = [];
    //   let deductions =
    //     this.$store.getters.userData.constantLists.listDeductionSlideType;
    //   for (let item in deductions) {
    //     this.slideTypeTokenOptions.push({
    //       value: deductions[item]["itemToken"],
    //       text: this.setDataMultiLang(
    //         this.language,
    //         deductions[item]["itemNameAr"],
    //         deductions[item]["itemNameEn"]
    //       ),
    //     });
    //   }
    //   this.isLoading = false;
    // },
    async getDialogOfSalaryEffects() {
      this.isLoading = true;
      this.employeeSalaryEffectTokenOptions = [];
      this.employeeSalaryEffectTokenOptions.push({
        value: "",
        text: this.$t("selectSalaryEffect"),
      });
      try {
        let response =
          await this.employeeSalaryEffectData.getDialogOfEmployeeSalaryEffect(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeSalaryEffectTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDialogOfSalaryEffects();
    // this.getDialogOfSlideType();
  },
};
</script>
