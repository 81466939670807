import { PAGE_SIZE } from "@/utils/constants";
import { DEDUCTION_SLIDE_TYPE_TOKENS } from "@/utils/constantLists";
import axios from "axios";
// import DeductionsPlansSlideDetails from "@/models/settings/settingsSalary/deductionsPlans/DeductionsPlansSlideDetails";

export default class DeductionsPlan {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.deductionsPlansToken = "";
    this.deductionsPlansCode = "";
    this.deductionsPlansNameAr = "";
    this.deductionsPlansNameEn = "";
    this.deductionsPlansNameUnd = "";
    this.deductionsPlansNameCurrent = "";
    this.deductionsPlansNote = "";
    this.dateTimeActionAdd = "";
    this.dateActionAdd = "";
    this.TimeActionAdd = "";
    this.cumulativeCalculationTypeToken = "";
    this.cumulativeCalculationTypeNameCurrent = "";
    this.cumulativeCalculationTypeNameAr = "";
    this.cumulativeCalculationTypeNameEn = "";
    this.cumulativeCalculationTypeNameUnd = "";
    this.cumulativeCalculationLateAttendaceStatus = false;
    this.cumulativeCalculationEarlyDepartureStatus = false;
    this.deductionsPlansArchiveStatus = "";
    // this.deductionsPlansDetailsSlideData = [new DeductionsPlansSlideDetails()];
    this.deductionsPlansDetailsSlideData = [];
    this.deductionsPlansDetailsSlideDataLatelyAttendance = [];
    this.deductionsPlansDetailsSlideDataEarlyDeparture = [];
    this.deductionsPlansDetailsSlideDataLostTime = [];
  }
  fillData(data) {
    this.deductionsPlansToken = data.deductionsPlansToken;
    this.deductionsPlansCode = data.deductionsPlansCode;
    this.deductionsPlansNameAr = data.deductionsPlansNameAr;
    this.deductionsPlansNameEn = data.deductionsPlansNameEn;
    this.deductionsPlansNameUnd = data.deductionsPlansNameUnd;
    this.deductionsPlansNameCurrent = data.deductionsPlansNameCurrent;
    this.deductionsPlansNote = data.deductionsPlansNote;
    this.dateTimeActionAdd = data.dateTimeActionAdd;
    this.dateActionAdd = data.dateActionAdd;
    this.TimeActionAdd = data.TimeActionAdd;
    this.cumulativeCalculationTypeToken = data.cumulativeCalculationTypeToken;
    this.cumulativeCalculationTypeNameCurrent =
      data.cumulativeCalculationTypeNameCurrent;
    this.cumulativeCalculationTypeNameAr = data.cumulativeCalculationTypeNameAr;
    this.cumulativeCalculationTypeNameEn = data.cumulativeCalculationTypeNameEn;
    this.cumulativeCalculationTypeNameUnd =
      data.cumulativeCalculationTypeNameUnd;
    this.cumulativeCalculationLateAttendaceStatus =
      data.cumulativeCalculationLateAttendaceStatus;
    this.cumulativeCalculationEarlyDepartureStatus =
      data.cumulativeCalculationEarlyDepartureStatus;
    this.deductionsPlansArchiveStatus = data.deductionsPlansArchiveStatus;
    this.deductionsPlansDetailsSlideData = data.deductionsPlansDetailsSlideData;

    //#region Custom me
    this.deductionsPlansDetailsSlideDataLatelyAttendance =
      data.deductionsPlansDetailsSlideData.filter(
        (x) => (x.slideTypeToken = DEDUCTION_SLIDE_TYPE_TOKENS.LatelyAttendance)
      );
    this.deductionsPlansDetailsSlideDataEarlyDeparture =
      data.deductionsPlansDetailsSlideData.filter(
        (x) => (x.slideTypeToken = DEDUCTION_SLIDE_TYPE_TOKENS.EarlyDeparture)
      );
    this.deductionsPlansDetailsSlideDataLostTime =
      data.deductionsPlansDetailsSlideData.filter(
        (x) => (x.slideTypeToken = DEDUCTION_SLIDE_TYPE_TOKENS.LostTime)
      );
    //#endregion Custom me
  }

  async getDeductionsPlanses(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch
  ) {
    try {
      let response = await axios.get(
        `/DeductionsPlans/GetDeductionsPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDeductionsPlanDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/DeductionsPlans/GetDeductionsPlansDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&deductionsPlansToken=${this.deductionsPlansToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfDeductionsPlans(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/DeductionsPlans/GetDialogOfDeductionsPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateDeductionsPlan(language, userAuthorizeToken) {
    //#region Custom me
    if (
      Array.isArray(this.deductionsPlansDetailsSlideDataLatelyAttendance) &&
      this.deductionsPlansDetailsSlideDataLatelyAttendance.length != 0
    ) {
      for (let item in this.deductionsPlansDetailsSlideDataLatelyAttendance) {
        this.deductionsPlansDetailsSlideDataLatelyAttendance[item][
          "slideTypeToken"
        ] = DEDUCTION_SLIDE_TYPE_TOKENS.LatelyAttendance;
      }
    } else {
      this.deductionsPlansDetailsSlideDataLatelyAttendance = [];
    }

    if (
      Array.isArray(this.deductionsPlansDetailsSlideDataEarlyDeparture) &&
      this.deductionsPlansDetailsSlideDataEarlyDeparture.length != 0
    ) {
      for (let item in this.deductionsPlansDetailsSlideDataEarlyDeparture) {
        this.deductionsPlansDetailsSlideDataEarlyDeparture[item][
          "slideTypeToken"
        ] = DEDUCTION_SLIDE_TYPE_TOKENS.EarlyDeparture;
      }
    } else {
      this.deductionsPlansDetailsSlideDataEarlyDeparture = [];
    }

    if (
      Array.isArray(this.deductionsPlansDetailsSlideDataLostTime) &&
      this.deductionsPlansDetailsSlideDataLostTime.length != 0
    ) {
      for (let item in this.deductionsPlansDetailsSlideDataLostTime) {
        this.deductionsPlansDetailsSlideDataLostTime[item]["slideTypeToken"] =
          DEDUCTION_SLIDE_TYPE_TOKENS.LostTime;
      }
    } else {
      this.deductionsPlansDetailsSlideDataLostTime = [];
    }

    let deductionsPlansDetailsSlideData = [
      ...this.deductionsPlansDetailsSlideDataLatelyAttendance,
      ...this.deductionsPlansDetailsSlideDataEarlyDeparture,
      ...this.deductionsPlansDetailsSlideDataLostTime,
    ];
    //#endregion Custom me

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      deductionsPlansToken: this.deductionsPlansToken,
      deductionsPlansCode: this.deductionsPlansCode,
      deductionsPlansNameAr: this.deductionsPlansNameAr,
      deductionsPlansNameEn: this.deductionsPlansNameEn,
      deductionsPlansNameUnd: this.deductionsPlansNameUnd,
      cumulativeCalculationTypeToken: this.cumulativeCalculationTypeToken,
      cumulativeCalculationLateAttendaceStatus:
        this.cumulativeCalculationLateAttendaceStatus,
      cumulativeCalculationEarlyDepartureStatus:
        this.cumulativeCalculationEarlyDepartureStatus,
      deductionsPlansNote: this.deductionsPlansNote,
      // deductionsPlansDetailsSlideData: this.deductionsPlansDetailsSlideData,
      deductionsPlansDetailsSlideData: deductionsPlansDetailsSlideData,
    };

    try {
      let response = "";
      if (
        this.deductionsPlansToken == "" ||
        this.deductionsPlansToken == undefined
      ) {
        response = await axios.post(
          `/DeductionsPlans//AddDeductionsPlans`,
          data
        );
      } else {
        response = await axios.post(
          `/DeductionsPlans//UpdateDeductionsPlans`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveDeductionsPlan(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      deductionsPlansToken: this.deductionsPlansToken,
    };

    try {
      let response = await axios.post(
        `/DeductionsPlans//ArchiveDeductionsPlans`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
