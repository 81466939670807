var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeEmployeesEnquirySalariesPaid",modifiers:{"usersPrivilegeEmployeesEnquirySalariesPaid":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("EmployeesEnquirySalariesPaids.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeEmployeesEnquirySalariesPaid"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView
            ),expression:"\n              usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesEnquirySalariesPaidView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView
            )?_vm._i(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesEnquirySalariesPaidView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd
            ),expression:"\n              usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesEnquirySalariesPaidAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesEnquirySalariesPaidAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete
            ),expression:"\n              usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesEnquirySalariesPaidDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesEnquirySalariesPaid.employeesEnquirySalariesPaidDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalariesPaid, "employeesEnquirySalariesPaidDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesEnquirySalariesPaidDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }