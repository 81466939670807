var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"BalancesPlanDetailsInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/balances-plans.svg")}}),_vm._v(" "+_vm._s(_vm.$t("balancesPlansSlideDetailsData"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.balancesPlansSlideDetailsData.employeeSalaryEffectNameCurrent,"title":_vm.$t('salaryEffectName'),"imgName":'salary-effects.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.convertTimeAndDaysToString(
          _vm.balancesPlansSlideDetailsData.maximumNumberInDayInSeconds,
          _vm.balancesPlansSlideDetailsData.maximumNumberInDayInMinutes,
          _vm.balancesPlansSlideDetailsData.maximumNumberInDayInHours,
          0
        ),"title":_vm.$t('maximumNumberInDay'),"imgName":'days.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.convertTimeAndDaysToString(
          _vm.balancesPlansSlideDetailsData.maximumNumberInMonthInSeconds,
          _vm.balancesPlansSlideDetailsData.maximumNumberInMonthInMinutes,
          _vm.balancesPlansSlideDetailsData.maximumNumberInMonthInHours,
          _vm.balancesPlansSlideDetailsData.maximumNumberInMonthInDays
        ),"title":_vm.$t('maximumNumberInMonth'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.convertTimeAndDaysToString(
          _vm.balancesPlansSlideDetailsData.maximumNumberInYearInSeconds,
          _vm.balancesPlansSlideDetailsData.maximumNumberInYearInMinutes,
          _vm.balancesPlansSlideDetailsData.maximumNumberInYearInHours,
          _vm.balancesPlansSlideDetailsData.maximumNumberInYearInDays
        ),"title":_vm.$t('maximumNumberInYear'),"imgName":'year.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.balancesPlansSlideDetailsData.balancesPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }