export default class UsersPrivilegeEmployeeFolderMedia {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeFolderMediaView = false;
    this.employeeFolderMediaAdd = false;
    this.employeeFolderMediaUpdate = false;
    this.employeeFolderMediaArchive = false;
    this.employeeFolderMediaRestore = false;
    this.employeeFolderMediaDelete = false;
  }
  fillData(data) {
    if (data) {
      this.employeeFolderMediaView = data.employeeFolderMediaView;
      this.employeeFolderMediaAdd = data.employeeFolderMediaAdd;
      this.employeeFolderMediaUpdate = data.employeeFolderMediaUpdate;
      this.employeeFolderMediaArchive = data.employeeFolderMediaArchive;
      this.employeeFolderMediaRestore = data.employeeFolderMediaRestore;
      this.employeeFolderMediaDelete = data.employeeFolderMediaDelete;
    } else {
      this.setInitialValue();
    }
  }
}
