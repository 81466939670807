var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeEmployeesEnquirySalaries",modifiers:{"usersPrivilegeEmployeesEnquirySalaries":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("SalaryInquiries.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeEmployeesEnquirySalaries"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesView
            ),expression:"\n              usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesEnquirySalariesView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesView
            )?_vm._i(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesEnquirySalariesView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesAdd
            ),expression:"\n              usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesEnquirySalariesAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesEnquirySalariesAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesDelete
            ),expression:"\n              usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesEnquirySalariesDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesEnquirySalaries.employeesEnquirySalariesDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesEnquirySalaries, "employeesEnquirySalariesDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesEnquirySalariesDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }