var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeJobAssignmentOrders",modifiers:{"usersPrivilegeJobAssignmentOrders":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("jobAssignmentOrders.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeJobAssignmentOrders"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView
            ),expression:"\n              usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"jobAssignmentOrdersView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView
            )?_vm._i(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView
            ,null)>-1:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"jobAssignmentOrdersView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd),expression:"usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd"}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"jobAssignmentOrdersAdd"},domProps:{"checked":Array.isArray(_vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd)?_vm._i(_vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd,null)>-1:(_vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd)},on:{"change":function($event){var $$a=_vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersAdd,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"jobAssignmentOrdersAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate
            ),expression:"\n              usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"jobAssignmentOrdersUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate
            )?_vm._i(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate
            ,null)>-1:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"jobAssignmentOrdersUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive
            ),expression:"\n              usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"jobAssignmentOrdersArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive
            )?_vm._i(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive
            ,null)>-1:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"jobAssignmentOrdersArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete
            ),expression:"\n              usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"jobAssignmentOrdersDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete
            )?_vm._i(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete
            ,null)>-1:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"jobAssignmentOrdersDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore
            ),expression:"\n              usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"jobAssignmentOrdersRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore
            )?_vm._i(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore
            ,null)>-1:(
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeJobAssignmentOrders.jobAssignmentOrdersRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeJobAssignmentOrders, "jobAssignmentOrdersRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"jobAssignmentOrdersRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }